.reel-section {
	width: 100%;
	background-color: #02030b;
	background-image: url('../../assets/main-Here-Section-1.svg');
	display: flex;
	flex-direction: column;
	background-size: cover;
	background-repeat: no-repeat;
	background-clip: padding-box;
	justify-content: center;
}

.reel-section h1 {
	font-size: 1.3rem !important;
	font-weight: 700 !important;
}

.reel {
	width: 100%;
	max-width: 100%;
	align-self: center;
}

.reel-container {
	width: 100%;
	align-self: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.play-btn-span {
	font-weight: 600;
	position: absolute;
	z-index: 100;
}

.reel-section .social-buttons {
	background: none;
	display: flex;
	flex-direction: row;
	border: none;
	align-items: center;
	justify-content: center;
	width: 50px;
	top: 100px;
	position: absolute;
	color: #FFFFFF !important;
	align-self: center;
}

.reel-section .social-btn {
	width: 40px;
	background: none;
	display: flex;
	border: none;
	align-items: center;
	justify-content: center;
	align-self: flex-end;
}

.reel-section .social-media-circle {
	align-self: flex-start;
}

.reel-section .social-icon {
	position: absolute;
	color: #F2F2F2;
}

/* Extra small devices (phones, 576px and down) */
@media (max-width: 576px) {
	.reel-section .social-buttons {
		top: 60px;
	}

	.reel-section .social-btn {
		width: 40px;
	}

	.reel-section .social-media-circle {
		width: 32px;
		height: 32px;
	}

	.reel-section .social-icon {
		width: 19px;
		height: 19px;
	}
}

/* Small devices (portrait tablets and large phones, 576px and up) */
@media (min-width: 576px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) {
	/* .reel {
		width: 90%;
		/* margin-top: 70px; 
	} */

	/* .reel-section h1 {
		top: 70px;
	} */
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1200px) {}