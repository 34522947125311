@import url('https://fonts.googleapis.com/css2?family=Arsenal+SC:ital,wght@0,400;0,700;1,400;1,700&family=Gruppo&family=Indie+Flower&display=swap');

.App {
  font-family: "Gruppo", sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 1600px;
  align-items: center;
  justify-content: center;
}
