
.about-page{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    padding-bottom: 30px;
}
.about-page h1{
    margin-bottom: 20px;
    position: relative;
    color: #FFFFFF;
}

.about-container{
    width: 90%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 800px;
}
.about-container .bg{
    width: 100%;
}
.about-container .portrait{
    width: 35%;
    max-width: 280px;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 50%;
    position: absolute;
    border: 2px solid #907EFC;
    margin-top: 15px;
}

.bio{
    margin-top: 20px;
    color: #FFFFFF;
    width: 90%;
    text-align: left;
}
/* Extra small devices (phones, 576px and down) */
@media (max-width: 576px) {
/* .about-page {
        padding-bottom: 220px;
    } */
}