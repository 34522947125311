footer {
    background-image: url('../../assets/main-Footer.svg');
        height: 150px;
        display: flex;
        flex-direction: column;
        background-size: cover;
        width: 100%;
        padding: 10px 20px;
        color: #FFFFFF;
        font-size: 24px;
        font-weight: 600;
}
.follow-me{
    position: relative;
}

footer .social-buttons {
    background: none;
    display: flex;
    flex-direction: row;
    border: none;
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
    position: relative;
    width: 160px;
    justify-content: space-between;
    margin-bottom: 20px;
}

footer .social-btn {
    width: 40px;
    background: none;
    display: flex;
    border: none;
    align-items: center;
    justify-content: center;
}

footer .social-media-circle {
    align-self: flex-start;
}

footer .social-icon {
    position: absolute;
    color: #F2F2F2;
   
}