.services-section {
	background-image: url('../../assets/main-4th-Section.svg');
	display: flex;
	flex-direction: column;
	background-size: cover;
	max-height: 700px;
	width: 100vw;
    padding: 20px 0 30px 0px;
    justify-content: center;
    align-items: center ;
}
.services-section .header {
	font-size: 24px;
    color: #FFFFFF;
    margin-bottom: 20px;
}
.services-container{
    display: flex;
    width: 95%;
    justify-content: space-between;
    align-self: center !important;
}

.service-box {
    width: 30% !important;
    height:auto;
    border-radius: 5px;
    background: #080C27;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
} 

.service-icon{
    font-size: 5rem !important;
    color: #BF2A2F;
}
.service{
    color: #FFFFFF;
    text-align: center;
    max-width: 100%;
    text-wrap:wrap;
    font-size: 0.9rem !important;
}
