

body {
    font-style: normal;
      font-weight: 100;
-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #050514 !important;
  color: #FFFFFF;
  display: flex;
    flex-direction: column;
  align-items: center;
  justify-content: center;
}